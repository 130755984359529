<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto">
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">
                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Ingreso</h4>
                  <p>Bienvenido, por favor ingresa tu cuenta.</p>
                </div>
                <div>
                  <vs-input name="email" icon-no-border icon="icon icon-user" icon-pack="feather" label-placeholder="Email" v-model="credentials.email" class="w-full" />
                  <vs-input type="password" name="password" icon-no-border icon="icon icon-lock" icon-pack="feather" label-placeholder="Password" v-model="credentials.password" class="w-full mt-6" />
                  <div class="flex flex-wrap justify-between my-5">
                    <router-link to="">¿Olvidaste tu Contraseña?</router-link>
                  </div>
                  <vs-button class="float-right" @click="doLogin">Ingresar</vs-button>
                  <vs-divider>-</vs-divider>
                  <div>
                    <small>® Inmobiliaria IS2</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  data: function() {
    return {
      credentials: {
        email: "",
        password: ""
      }
    }
  },
  methods: {
    ...mapActions('AuthenticationStore', ['login', 'current', 'authInit']),
    doLogin: function() {
      this.login(this.credentials)
        .then(() => {
          //console.log("doLogin");
          //this.doCurrent();
          this.$router.push({
            name: "home"
          });
        });
    },
    doCurrent: function() {
      this.current(this.credentials)
        .then(() => {
          //console.log("doCurrent");
          this.$router.push({
            name: "home"
          });
        });
    }
  },
  created: function() {
    this.authInit();
  }
};

</script>
<style lang="scss">
#page-login {
  .social-login-buttons {
    .bg-facebook {
      background-color: #1551b1
    }

    .bg-twitter {
      background-color: #00aaff
    }

    .bg-google {
      background-color: #4285F4
    }

    .bg-github {
      background-color: #333
    }
  }
}

</style>
